var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('div',{staticClass:"d-flex py-2"},[_c('v-btn',{staticClass:"ma-1",attrs:{"color":"primary","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.getClientes($event)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-reload")]),_vm._v("recargar ")],1),_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary","small":"","disabled":_vm.selected.length == 0},on:{"click":function($event){$event.stopPropagation();return _vm.exportarTodos(_vm.selected)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-download")]),_vm._v("Descargar seleccionados ")],1)],1),_c('v-card',[_c('v-data-table',{staticClass:"fixed-checkbox",attrs:{"fixed-header":"","items":_vm.clientes,"headers":_vm.clientesHeader,"show-select":"","item-key":"IdCliente","single-select":false,"calculate-widths":true,"search":_vm.search,"checkbox-color":"secondary","loading":_vm.loading,"footer-props":{
				showFirstLastPage: true,
				showCurrentPage: true,
				itemsPerPageOptions: [10, 25, 50],
			}},scopedSlots:_vm._u([{key:"body.prepend",fn:function(ref){
			var headers = ref.headers;
return [_c('table-filters',{attrs:{"headers":headers,"items":_vm.clientes},model:{value:(_vm.inlineFilters),callback:function ($$v) {_vm.inlineFilters=$$v},expression:"inlineFilters"}})]}},{key:"item.Identidad",fn:function(ref){
			var item = ref.item;
return [_c('table-button',{attrs:{"to":("/clientes/detalles?idCliente=" + (item.IdCliente)),"tooltip":"Detalles del Cliente","color":"secondary"}},[_vm._v(_vm._s(item.Identidad))])]}},{key:"item.consumosEstimados",fn:function(ref){
			var item = ref.item;
return [(item.consumosEstimados)?_c('span',[_vm._v(_vm._s(_vm.redondear(item.consumosEstimados)))]):_vm._e()]}},{key:"item.Email",fn:function(ref){
			var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
			var on = ref.on;
			var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(item.Email),expression:"item.Email"}],attrs:{"link":"","href":("mailto:" + (item.Email)),"color":"secondary","text":""}},'v-btn',attrs,false),on),[_vm._v(_vm._s(item.Email))])]}}],null,true)},[_c('span',[_vm._v("Enviar Email")])])]}},{key:"item.CESCE",fn:function(ref){
			var item = ref.item;
return [(item.CESCE == '0')?_c('v-icon',{attrs:{"small":"","color":"error"}},[_vm._v("mdi-close-circle-outline")]):_c('v-icon',{attrs:{"small":"","color":"success"}},[_vm._v("mdi-checkbox-marked-circle-outline")])]}},{key:"item.CambioPassword",fn:function(ref){
			var item = ref.item;
return [(item.CambioPassword == '0')?_c('v-icon',{attrs:{"small":"","color":"error"}},[_vm._v("mdi-close-circle-outline")]):_c('v-icon',{attrs:{"small":"","color":"success"}},[_vm._v("mdi-checkbox-marked-circle-outline")])]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1),(_vm.$root.acceso('DESCARGAR_CSV_CONTRATOS'))?_c('div',{staticClass:"d-flex flex-wrap mt-4",staticStyle:{"gap":"16px"}},[_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":_vm.exportarTodos}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-download")]),_vm._v("Exportar todos ")],1)],1):_vm._e(),_c('portal-target',{attrs:{"name":"filter-btn"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }