<template>
	<v-container fluid>
		<div class="d-flex py-2">
			<v-btn color="primary" small class="ma-1" @click.stop="getClientes">
				<v-icon left>mdi-reload</v-icon>recargar
			</v-btn>
			<v-spacer />
			<v-btn
				@click.stop="exportarTodos(selected)"
				text
				color="primary"
				small
				:disabled="selected.length == 0"
			>
				<v-icon left>mdi-download</v-icon>Descargar seleccionados
			</v-btn>
		</div>
		<v-card>
			<v-data-table
				fixed-header
				:items="clientes"
				:headers="clientesHeader"
				show-select
				class="fixed-checkbox"
				v-model="selected"
				item-key="IdCliente"
				:single-select="false"
				:calculate-widths="true"
				:search="search"
				checkbox-color="secondary"
				:loading="loading"
				:footer-props="{
					showFirstLastPage: true,
					showCurrentPage: true,
					itemsPerPageOptions: [10, 25, 50],
				}"
			>
				<template v-slot:body.prepend="{ headers }">
					<table-filters :headers="headers" :items="clientes" v-model="inlineFilters"></table-filters>
				</template>

				<template v-slot:item.Identidad="{ item }">
					<table-button
						:to="`/clientes/detalles?idCliente=${item.IdCliente}`"
						tooltip="Detalles del Cliente"
						color="secondary"
					>{{ item.Identidad }}</table-button>
				</template>
				<template v-slot:item.consumosEstimados="{ item }">
					<!-- <table-button :to="`/clientes/detalles?idCliente=${item.IdCliente}`" tooltip="Detalles del Cliente" color="secondary">{{ item.Identidad }}</table-button> -->
					<span v-if="item.consumosEstimados">{{ redondear(item.consumosEstimados) }}</span>
				</template>
				<template v-slot:item.Email="{ item }">
					<v-tooltip bottom>
						<template v-slot:activator="{ on, attrs }">
							<v-btn
								v-on="on"
								v-bind="attrs"
								link
								:href="`mailto:${item.Email}`"
								color="secondary"
								text
								v-show="item.Email"
							>{{ item.Email }}</v-btn>
						</template>
						<span>Enviar Email</span>
					</v-tooltip>
				</template>

				<template v-slot:item.CESCE="{ item }">
					<v-icon small v-if="item.CESCE == '0'" color="error">mdi-close-circle-outline</v-icon>
					<v-icon small v-else color="success">mdi-checkbox-marked-circle-outline</v-icon>
				</template>

				<template v-slot:item.CambioPassword="{ item }">
					<v-icon small v-if="item.CambioPassword == '0'" color="error">mdi-close-circle-outline</v-icon>
					<v-icon small v-else color="success">mdi-checkbox-marked-circle-outline</v-icon>
				</template>
			</v-data-table>
		</v-card>

		<div
			class="d-flex flex-wrap mt-4"
			style="gap: 16px"
			v-if="$root.acceso('DESCARGAR_CSV_CONTRATOS')"
		>
			<v-btn text color="primary" @click="exportarTodos">
				<v-icon left>mdi-download</v-icon>Exportar todos
			</v-btn>
		</div>

		<portal-target name="filter-btn" />
	</v-container>
</template>

<script>
// import axios from "redaxios";
import { processToken, jsonToCsv, perColumnFilter, parseDate } from "@/utils/index.js";

export default {
	components: {
		TableFilters: () => import("@/components/TableFilters.vue"),
		TableButton: () => import("@/components/TableButton.vue"),
	},
	data() {
		return {
			selected: [],
			clientes: [],
			loading: false,
			search: null,
			clientesHeader: [
				{ text: "Identificador", value: "Identidad" },
				{ text: "Nombre Cliente", value: "denominacion" },
				{ text: "Dirección", value: "direccion" },
				{ text: "Móvil", value: "movil" },
				{ text: "Fijo", value: "fijo" },
				{ text: "Email", value: "email" },
				{ text: "Consumos estimados", value: "consumosEstimados" },
				{ text: "Contratos Activados", value: "nContratosActivados" },
				{ text: "Contratos Baja", value: "nContratosBaja" },
				{ text: "CESCE", value: "CESCE", dataType: 'bool', align: 'center', acceso: 'CONTROL_MONITORIO_IMPAGADOS' },
				{ text: "OV", value: "CambioPassword", dataType: 'bool', align: 'center' },
			].filter(header => (!header.acceso || this.$root.acceso(header.acceso))).map((header) => {
				return {
					class: "text-no-wrap sticky-header",
					cellClass: "pa-2 text-no-wrap",
					filter: (value) =>
						perColumnFilter(
							value,
							this.inlineFilters[header.value],
							header.dataType
						),
					dataType: "text",
					...header,
				};
			}),

			inlineFilters: {},
		};
	},
	methods: {
		parseDate,
		redondear(bytes, dp = 3) {
			const thresh = 1000;

			if (Math.abs(bytes) < thresh) {
				return bytes + " Wh";
			}

			const units = ["kWh", "MWh", "GWh", "TWh", "PWh", "EWh", "ZWh", "YWh"];
			let u = -1;
			const r = 10 ** dp;

			do {
				bytes /= thresh;
				++u;
			} while (
				Math.round(Math.abs(bytes) * r) / r >= thresh &&
				u < units.length - 1
			);

			return bytes.toFixed(dp) + " " + units[u];
		},
		exportarTodos(items) {
			const getDatatype = (key) =>
				(this.clientesHeader.find((h) => h.value == key) || {}).dataType;

			if (!Array.isArray(items))
				items = this.clientes.filter((con) =>
					Object.entries(con).every(([key, value]) =>
						perColumnFilter(value, this.inlineFilters[key], getDatatype(key))
					)
				);

			const csvHeaders = Object.keys(items[0]).map(k => ({ key: k, dataType: getDatatype(k) }))

			jsonToCsv(items, csvHeaders, 'CLIENTES_' + parseDate(Date.now(), false, false));
		},
		getClientes() {
			this.loading = true;
			let str = this.$store.getters.getDatosEmpresa;

			const { IdUsuario, IdAgente } = str.iss;
			const IdEmpresa = this.$store.getters.getIdEmpresa;

			axios({
				method: "GET",
				url: `${process.env.VUE_APP_OUR_API_URL}/clientes.php`,
				params: {
					token: this.$store.getters.getJwtEmpresa,
					IdAgente,
				}
			}).then(({ data }) => {
				this.clientes = [
					...new Map(data.map((item) => [item.Identidad, item])).values(),
				].map((item) => {
					return {
						...item,
						denominacion: [item.Nombre, item.Apellido1, item.Apellido2, item.RazonSocial]
							.filter((i) => !!i)
							.join(" "),
						direccion: [item.NombreCalle, item.NumeroTitular, ',', item.TextoCiudad, item.CodPostal, item.TextoProvincia]
							.filter((i) => !!i)
							.join(" "),
					};
				});
				this.loading = false;
			});
		}
	},
	mounted() {
		this.getClientes();
	},
};
</script>

<style scoped>
.v-data-table /deep/ [role="columnheader"] {
	/* background: #f1f1f1 !important; */
}
</style>